<template>
  <el-upload
    class="xml-upload"
    action="#"
    :on-change="onUploadChange"
    :auto-upload="false"
    :file-list="fileList"
    :limit="1"
    :multiple="false"
    :show-file-list="false"
  >
    <el-input :value="fileName" :placeholder="placeholder" size="mini" readonly>
      <el-button size="mini" type="primary" slot="append" icon="el-icon-upload2"></el-button>
    </el-input>
  </el-upload>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'XmlUpload',

  props: {
    value: String,

    placeholder: {
      type: String,
      default: 'Выберите XML файл'
    }
  },

  watch: {
    value () {
      if (this.localValue !== this.value) {
        this.localValue = this.value
      }
    }
  },

  computed: {
    fileName () {
      if (this.fileList.length > 0) {
        return this.fileList[0].name
      }

      return null
    }
  },

  data () {
    return {
      localValue: null,
      fileList: []
    }
  },

  mounted () {
    this.localValue = this.value
  },

  methods: {
    onUploadChange (file, fileList) {
      this.fileList = [file]

      if (!['application/xml', 'text/xml'].includes(file.raw.type)) {
        this.fileList = []
        this.$message.error('Необходимо выбрать XML файл')
        return false
      }

      const reader = new FileReader()
      reader.readAsText(file.raw, 'UTF-8')
      reader.onload = () => {
        this.localValue = reader.result

        this.$emit('input', this.localValue)
      }
      reader.onerror = () => {
        this.$message.error('Возникла ошибка при чтении XML файла')

        console.error(reader.error)
      }

      return true
    }
  }
})
</script>

<style lang="scss">
  .xml-upload .el-upload {
    width: 100%;
  }
</style>
